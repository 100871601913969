.Footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2.5rem; 
  background-color: gray;
}

.Footer span{
  color: white;
  padding-left: 1.5rem;
}

.Footer img {
  max-height: 2.5rem;
  padding-left: 2.5rem;
}

.Footer a {
  text-decoration: none;
  color: white;
}

.Footer a:hover {
  color: lightgray;
}

@media (max-width: 500px){
  .connect {
    display: none;
  }
}
