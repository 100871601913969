div.comingSoon {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  padding-top: 15%;
  color: white;
}
.comingSoon h1 {
  font-size: 45px;
  letter-spacing: 3px
}
.comingSoon h1 {
  font-size: 35px;
}
.comingSoon p {
  font-size: 25px;
}

div.comingSoon::after {
  content: "";
  background: linear-gradient(
    rgba(0, 0, 0, 0.8), 
    rgba(0, 0, 0, 0.8)
  ),
  url('/world.jpg');
  opacity: 0.6;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;   
}

.gadin {
  font-size: 30px;
  line-height: 1.2;
  font-weight: bold;
}

.tech {
  font-size: 9px;font-weight: bold;
}

.logoImage { 
  float: left;
  padding-right: 5px;
}

.contentDiv {
  margin-top: 5%;
}

.connect a {
  text-decoration: none;
  color: white;
  font-size: 2em;
}

.connect a:hover {
  color: lightgray;
}

.connect span{
  color: white;
  padding-left: 1.3rem;
}

.footerMargin {
  margin-bottom: 3rem;
}

.mainArea {
  max-width: 600px;
}

@media (max-width: 500px){
  .topMargin {
    margin-top: 1rem;
  }
}

@media (min-width: 500px){
  .connect {
    display: none;
  }
}


