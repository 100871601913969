.card { 
  margin-bottom: 1.4em;
  border: 0;
  cursor: pointer;
  text-align: center;
}

.images {
  width: auto; 
  max-width: 100%;
  max-height: 100px;
  margin-bottom: 1%;
  margin: auto;
}

.smartmirror {
  font-weight: bold;
  padding-left: 10px;
  font-size: 2em;
}

.CompanyPage div {
  margin-top: 20px;
  transition: 2s;
}

.CompanyPage .images {
  max-width: 25%;
}

.CompanyPage Button {
  margin-top: 2%;
  margin-bottom: 5%;
}

.description {
  width: 60%;
}

@media (max-width: 600px) {
  .description {
    width: 95%;
  }
  .CompanyPage Button {
    margin-top: 2%;
    margin-bottom: 3rem;
  }
  .CompanyPage .images {
    max-width: 60%;
  }
}



